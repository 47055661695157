import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Journals } from '../../../../types/Journals.types';
import JournalsTable from './JournalsTable';
import useFeatureFlags from '../../../../hooks/useFeatureFlag';

interface JournalContainerProps {
  isAdding: boolean | undefined;
  journals: Journals[];
  previewJournals: Journals[];
}
export const JournalsContainer: React.FC<JournalContainerProps> = ({
  isAdding,
  journals,
  previewJournals,
}) => {
  const { enableFilePreviews } = useFeatureFlags();
  if (!isAdding) {
    return (
      <Card>
        <CardContent style={{ padding: 24 }}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <JournalsTable agreementJournals={journals} preview={false} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  } else if (previewJournals.length > 0 && enableFilePreviews) {
    return (
      <Card>
        <CardContent style={{ padding: 24 }}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <JournalsTable
                agreementJournals={previewJournals}
                preview={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
};
