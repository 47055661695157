import React, { useCallback, useEffect } from 'react';

export type Order = 'asc' | 'desc';

export const usePersistentSorting = <T extends object>(
  defaultOrderBy: keyof T,
  sortName: string,
  defaultOrder: Order = 'asc'
) => {
  const [order, setOrder] = React.useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = React.useState<keyof T>(defaultOrderBy);
  const storedOrder = sessionStorage.getItem(
    `persistent-sorting-${sortName}-order`
  );
  const storedOrderBy = sessionStorage.getItem(
    `persistent-sorting-${sortName}-orderBy`
  );
  useEffect(() => {
    if (storedOrder !== undefined && storedOrder !== order) {
      setOrder(storedOrder as Order);
    }
    if (storedOrderBy !== undefined && storedOrderBy !== orderBy) {
      setOrderBy(storedOrderBy as keyof T);
    }
  }, [order, orderBy, sortName, storedOrder, storedOrderBy]);
  const wrappedSetOrder = useCallback(
    (order: Order) => {
      sessionStorage.setItem(`persistent-sorting-${sortName}-order`, order);
      setOrder(order);
    },
    [sortName]
  );
  const wrappedSetOrderBy = useCallback(
    (orderBy: keyof T) => {
      sessionStorage.setItem(
        `persistent-sorting-${sortName}-orderBy`,
        orderBy.toString()
      );
      setOrderBy(orderBy);
    },
    [sortName]
  );
  return {
    order,
    setOrder: wrappedSetOrder,
    orderBy,
    setOrderBy: wrappedSetOrderBy,
  };
};
