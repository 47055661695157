import React from 'react';
import styled from '@emotion/styled';

import { Grid, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const Footer = styled.div`
  background-color: ${process.env.REACT_APP_BUILD === 'staging'
    ? '#083833'
    : '#0b313f'};
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user } = useAuth0();
  return (
    <Footer {...rest}>
      <Grid
        container
        paddingX={12}
        paddingY={12}
        display="flex"
        flexDirection="column"
      >
        <Grid item sx={{ textAlign: 'center' }}>
          {!!user && (
            <FooterText
              variant="body1"
              sx={{
                maxWidth: '190px',
                overflow: 'hidden',
                fontSize: '14px',
                textOverflow: 'ellipsis',
                fontWeight: 600,
              }}
            >
              {user.email}
            </FooterText>
          )}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
