import { Box, Button } from '@mui/material';
import { useAuthContext } from '../../contexts/AuthContext';
import { Lock, LockOpen } from '@mui/icons-material';

export function NavbarImpersonationLock() {
  const { impersonationLock, setImpersonationLock } = useAuthContext();

  return (
    <Button
      variant="text"
      color="secondary"
      sx={{ minWidth: { xs: '40px !important', lg: 'auto' } }}
      onClick={() => setImpersonationLock(!impersonationLock)}
    >
      {impersonationLock ? (
        <>
          <Lock sx={{ fontSize: 16 }} />
          <Box
            component="div"
            sx={{
              paddingLeft: 4,
              display: {
                xs: 'none',
                sm: 'none',
                md: 'inline',
              },
            }}
          >
            Unlock
          </Box>
        </>
      ) : (
        <>
          <LockOpen sx={{ fontSize: 16 }} />
          <Box
            component="div"
            sx={{
              paddingLeft: 4,
              display: {
                xs: 'none',
                sm: 'none',
                md: 'inline',
              },
            }}
          >
            Lock
          </Box>
        </>
      )}
    </Button>
  );
}
