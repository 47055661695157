import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Alert as MuiAlert,
  Grid,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddUserDialog from './components/AddUserDialog';
import DeleteUserDialog from './components/DeleteUserDialog';
import { useUsers } from '../../hooks/api/useUsers';
import { useAuthContext } from '../../contexts/AuthContext';

const Alert = styled(MuiAlert)`
  position: fixed;
  padding: 12px;
  right: 0;
  top: 70px;
  z-index: 1;
`;

type Users = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  app_metadata: {
    account: string;
  };
  last_login: string;
  updated_at: string;
};

const UserManagementTable = () => {
  const { impersonationLock } = useAuthContext();
  const [users, setUsers] = useState<Users[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const { fetchUsers, deleteUser } = useUsers();

  useEffect(() => {
    fetchUsers().then((res) => {
      setUsers(res);
      setDeleted(false);
      setNewUser(false);
    });
  }, [deleted, newUser, fetchUsers]);

  useEffect(() => {
    if (deleted) {
      setDeleteAlert(true);
    }
    const timer = setTimeout(() => {
      setDeleteAlert(false);
    }, 1600);

    return () => clearTimeout(timer);
  }, [deleted]);

  const onDeleteUserHandler = (email: string) => {
    deleteUser(email).then(() => setDeleted(true));
  };

  const { t } = useTranslation();

  return (
    <>
      {deleteAlert && (
        <Alert severity="success">User has been deleted successfully!</Alert>
      )}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        p={12}
        component={Paper}
        mb={12}
      >
        <Grid item>
          <Typography variant="h1" sx={{ fontSize: 20 }}>
            {t('home.userManagementTitle')}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Manage your JST admins including adding or removing accounts
          </Typography>
        </Grid>
        <Grid item>
          <AddUserDialog
            text="Add Admin"
            onClick={() => setNewUser(true)}
            disabled={impersonationLock}
          />
          <Icon />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="Table showing a list of users"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: '2px solid #E0E0E0' }}>
              <TableCell sx={{ fontSize: '14px' }}>
                {t('userManagement.account')}
              </TableCell>
              <TableCell sx={{ fontSize: '14px' }}>
                {t('userManagement.email')}
              </TableCell>
              <TableCell sx={{ fontSize: '14px' }}>
                {t('userManagement.updatedAt')}
              </TableCell>
              <TableCell sx={{ fontSize: '14px' }}>
                {t('userManagement.lastLogin')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow
                key={`${user.email}-${user.app_metadata?.account}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#eee',
                  },
                }}
              >
                <TableCell>{user.app_metadata?.account}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {new Date(user.updated_at).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {user.last_login
                    ? new Date(user.last_login).toLocaleDateString()
                    : ''}
                </TableCell>
                <TableCell>
                  <DeleteUserDialog
                    disabled={impersonationLock}
                    onClick={() => onDeleteUserHandler(user.email)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserManagementTable;
