import styled from '@emotion/styled';
import { QuestionMarkRounded } from '@mui/icons-material';
import { Grid, TextField, TextareaAutosize, Typography } from '@mui/material';
import { spacing } from '@mui/system';

/** PRESETS FOR AGREEMENT FORM */
export const StyledFormDivider = styled.div(spacing);

//Create a grid with styling
export const StyledFormGrid = styled(Grid)(spacing);

//Create a text field with styling
export const StyledTextField = styled(TextField)(spacing);

//Create a typography with styling
export const StyledFormTypography = styled(Typography)(spacing);

export const StyledSubmit = styled.input`
  && {
    width: 140px;
    height: 35px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: #197d8e;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #125863;
    }
  }
`;

export const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  min-height: 60px;
  padding: 12px;
  border-radius: 5px;
  border-color: #c4c4c4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  &:hover {
    border-color: black;
  }
  &:focus {
    outline: none;
    border-width: 2px;
    border-color: #197d8e;
    padding: 11px;
  }
`;

export const StyledQuestionMark = styled(QuestionMarkRounded)`
  color: #197d8e;
  border-radius: 10px;
  font-size: 14px;
  padding: 1px;
  border: 1px solid #197d8e;
`;
