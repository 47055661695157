import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Box, Divider, Button as MuiButton, Typography } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';
import Logo from '../../assets/pictures/sf-2_logo.png';

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const StyledCode = styled(Typography)`
  width: fit-content;
  font-size: 100px;
  margin-bottom: 0px;
  color: #287e8c;
`;

const StyledCodeMessage = styled(Typography)`
  width: fit-content;
  font-size: 40px;
`;

const StyledCodeText = styled(Typography)`
  width: fit-content;
  font-size: 20px;
  margin-bottom: 20px;
`;

const LogoImg = styled.img`
  width: 140px;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

function Page500() {
  return (
    <Container>
      <Helmet title="500 Error" />
      <LogoImg src={Logo} />
      <Box
        sx={{
          alignSelf: 'flex-start',
          marginTop: '10%',
          marginLeft: '7%',
          width: '90%',
        }}
      >
        <StyledCode variant="h1" align="center" gutterBottom sx={{}}>
          500
        </StyledCode>
        <StyledCodeMessage variant="h5" gutterBottom>
          Internal server error
        </StyledCodeMessage>
        <StyledCodeText variant="body1" gutterBottom>
          The server encountered something unexpected that didn't allow it to
          complete the request
        </StyledCodeText>
        <Divider />
        <ButtonWrapper>
          <a
            href="mailto:support@scifree.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="outlined" color="primary" mr={10}>
              Contact us
            </Button>
          </a>
          <Button component={Link} to="/" variant="contained" color="primary">
            Return to JST-Admin
          </Button>
        </ButtonWrapper>
      </Box>
    </Container>
  );
}

export default Page500;
