const Colors = {
  white: '#fff',
  black: '#000',
  blue: '#1f2344',
  blueBorder: '#1f2344',
  darkBlue: '#1d1e3c',
  red: '#f95759',
  redBorder: '#f95759',
  teal: '#1fb4a8',
  tealBorder: '#386c68',
  tealBorderBackground: 'rgba(55, 108, 103, 0.36)',
  tealBackground: 'rgba(31, 180, 168, 0.36)',
  tealLight: '#52cec5',
  tealLightBorder: '#56908d',
  grayLightBackground: '#F9F9F9',
  gray: '#ececec',
  gray2: '#cecece',
  gray3: '#9495A5',
  grayDark: '#afafaf',
  grayDarkBorder: '#767676',
  grayLightBorder: '#E8E9EC',
  sliderGray: '#787b8e',
  pink: '#f57c7e',
  orange: '#e7635f',
  whiteOpacity: 'rgba(249,249,249,0.2)',
  scifreePrimary: '#197D8E',
  scifreeText: '#0B313F',
  scifreeBg: '#F3F6F9',
  orangeChart: '#fd7f6f',
  blueChart: '#7eb0d5',
  greenChart: '#b2e061',
  purpleChart: '#bd7ebe',
  yellowChart: '#ffb55a',
};

export default Colors;
