import Resizer from 'react-image-file-resizer';
export type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const sizes: Record<Size, number> = {
  xl: 1920,
  lg: 1280,
  md: 960,
  sm: 640,
  xs: 320,
};

export const resizeImage = (file: File, size: Size = 'md'): Promise<string> =>
  new Promise((resolve, reject) => {
    // I want this to return the same file if it's an SVG
    if (file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result as string);
      };
      reader.readAsDataURL(file);
      return;
    }
    if (!file.type.startsWith('image/')) {
      reject('File is not an image.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const originalBase64 = event.target?.result as string;
      const originalFileSize = Buffer.byteLength(originalBase64, 'base64');

      Resizer.imageFileResizer(
        file,
        sizes[size],
        sizes[size],
        'PNG',
        100,
        0,
        (uri: string | Blob | File | ProgressEvent<FileReader>) => {
          if (typeof uri !== 'string') {
            reject('Not a string');
            return;
          }

          const resizedFileSize = Buffer.byteLength(uri, 'base64');

          // If the resized image is larger, return the original image's base64 string
          // Otherwise, return the resized image's base64 string
          resolve(resizedFileSize < originalFileSize ? uri : originalBase64);
        },
        'base64'
      );
    };
    reader.readAsDataURL(file);
  });
