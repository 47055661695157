export const addPropertyIfNotEmpty = (
  obj: Record<string, any>,
  key: string,
  value: any
) => {
  if (
    value !== '' &&
    !(
      value === '<p><br></p>' &&
      ['no_results', 'instructions', 'info_box', 'search_title'].includes(key)
    )
  ) {
    const keys = key.split(/\.|\["|"]/).filter((k) => k !== '');

    let currentObj = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      const nestedKey = keys[i];
      if (!currentObj[nestedKey]) {
        currentObj[nestedKey] = {};
      }
      currentObj = currentObj[nestedKey];
    }

    currentObj[keys[keys.length - 1]] = value;
  }
};

export function convertImageToBase64(imageData: string): string {
  let decodedData = '';
  let base64String = '';

  if (imageData.startsWith('data:image/svg+xml;base64,')) {
    imageData = imageData.replace('data:image/svg+xml;base64,', '');
    decodedData = window.atob(imageData);
    base64String = btoa(decodedData);
  } else if (imageData.startsWith('data:image/png;base64,')) {
    imageData = imageData.replace('data:image/png;base64,', '');
    decodedData = atob(imageData);
    base64String = btoa(decodedData);
  }

  return base64String;
}

export const getBase64String = async (
  e: React.ChangeEvent<HTMLInputElement>
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      // Ensure the file is an image
      if (!selectedFile.type.startsWith('image/')) {
        reject('File is not an image.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        // FileReader result contains a base64-encoded string
        // of the file's data
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reader.abort();
        reject('Problem parsing input file.');
      };
      reader.readAsDataURL(selectedFile);
    } else {
      resolve(null);
    }
  });
};
