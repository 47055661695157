import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';

function SignIn() {
  const { loginWithRedirect } = useAuth0();

  useMemo(() => {
    setTimeout(() => {
      loginWithRedirect({
        appState: { targetUrl: process.env.REACT_APP_AUTH0_REDIRECT_URI },
      });
    }, 1500);
  }, [loginWithRedirect]);

  return (
    <React.Fragment>
      <CircularProgress sx={{ color: 'white' }} />
    </React.Fragment>
  );
}

export default SignIn;
