import { useCallback } from 'react';
import { useApi } from './useApi';

export const useStatistics = () => {
  const { makeApiRequest } = useApi();

  const fetchStatisticsTop10 = useCallback(async () => {
    return (await makeApiRequest)('/statistics/top10', 'GET');
  }, [makeApiRequest]);

  const fetchStatisticsNoResults = useCallback(async () => {
    return (await makeApiRequest)('/statistics/no_results', 'GET');
  }, [makeApiRequest]);

  const fetchStatisticsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/by_date?from=${startDate}&to=${endDate}`,
        'GET'
      );
    },
    [makeApiRequest]
  );

  const fetchJournalsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/journals?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((journals: any) => {
        return journals.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  const fetchFiltersByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/filters?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((filters: any) => {
        return filters.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  const fetchEventsByDate = useCallback(
    async (startDate: string, endDate: string) => {
      return (await makeApiRequest)(
        `/statistics/events?from=${startDate}&to=${endDate}`,
        'GET'
      ).then((events: any) => {
        return events.sort((a: any, b: any) => {
          return b.count - a.count;
        });
      });
    },
    [makeApiRequest]
  );

  return {
    fetchStatisticsTop10,
    fetchStatisticsNoResults,
    fetchStatisticsByDate,
    fetchJournalsByDate,
    fetchFiltersByDate,
    fetchEventsByDate,
  };
};
