import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGetAccessToken } from '../useGetAccessToken';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

type AddUserArgs = {
  data: {
    email: string;
  };
};

type CreateAccountArgs = {
  data: {
    name: string;
    path: string;
  };
};

export const useUsers = () => {
  const { getToken } = useGetAccessToken();
  const { account } = useAuthContext();
  const { user } = useAuth0();
  const currentAccount =
    user?.[`https://api.jstscifree.com/app_metadata`]?.account;
  const scifreeAdmin = currentAccount === 'admin';

  const fetchUsers = useCallback(async () => {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/users`, {
      headers: scifreeAdmin
        ? {
            Authorization: `Bearer ${token}`,
            'X-SciFree-Context': account,
          }
        : {
            Authorization: `Bearer ${token}`,
          },
    });
    return response.data;
  }, [getToken, scifreeAdmin, account]);

  const deleteUser = async (email: string) => {
    const token = await getToken();

    await axios.delete(`${baseUrl}/users/${email}`, {
      headers: scifreeAdmin
        ? {
            Authorization: `Bearer ${token}`,
            'X-SciFree-Context': account,
          }
        : {
            Authorization: `Bearer ${token}`,
          },
    });
  };

  const addUser = async ({ data }: AddUserArgs) => {
    try {
      const token = await getToken();

      await axios.post(`${baseUrl}/users`, data, {
        headers: scifreeAdmin
          ? {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-SciFree-Context': account,
            }
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchAccount = useCallback(async (): Promise<
    Record<string, string> | undefined
  > => {
    try {
      const token = await getToken();

      const response = await axios.get(`${baseUrl}/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.error(error);
    }
  }, [getToken]);

  const createAccount = async ({ data }: CreateAccountArgs) => {
    const token = await getToken();

    return axios.post(`${baseUrl}/accounts`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-SciFree-Context': account,
      },
    });
  };

  return { fetchUsers, deleteUser, addUser, fetchAccount, createAccount };
};
