import React from 'react';
import {
  Button,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  OpenInNew,
  PlaylistAdd,
  UnfoldMore,
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { AgreementsTableViewComponent } from '../../Agreements.types';
import {
  AgreementIsActive,
  Agreements,
} from '../../../../types/Agreements.types';
import { Journals } from '../../../../types/Journals.types';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../../../../components/Loader';
import {
  navigateToAgreement,
  navigateToNewAgreement,
} from '../../../../helpers/browser';
import {
  StyledTable,
  StyledTableBox,
  StyledTableButton,
  StyledTableDiv,
  StyledTableRow,
} from '../../presetStyles/tablePresets';
import dayjs from 'dayjs';
import { useBranding } from '../../../../hooks/api/useBranding';
import styled from '@emotion/styled';
import { usePersistentSorting } from '../../../../hooks/usePersistentSorting';
import { useAuthContext } from '../../../../contexts/AuthContext';

const arrowStylingDefault = {
  transition: 'transform 150ms ease',
};

const arrowStyling = {
  transform: 'rotate(-180deg)',
  transition: 'transform 150ms ease',
};

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const StyledRepositoryLinkButton = styled(Button)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

export const AgreementsTableView: AgreementsTableViewComponent = ({
  agreements,
  journals,
}) => {
  const { impersonationLock } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading } = useAuth0();
  const { fetchBranding } = useBranding();

  const { order, orderBy, setOrder, setOrderBy } =
    usePersistentSorting<Agreements>('_id', 'agreements');

  const [repository, setRepository] = React.useState<string>('');

  const handleOpen = (
    agreementId: string,
    event: React.MouseEvent<HTMLElement>,
    navigate: any
  ) => {
    navigateToAgreement(agreementId, event, navigate);
  };

  const newAgreement = (
    event: React.MouseEvent<HTMLElement>,
    navigate: any
  ) => {
    navigateToNewAgreement(event, navigate);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Agreements
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    const fetchRepository = async () => {
      const { repo_url } = await fetchBranding();
      setRepository(repo_url);
    };
    fetchRepository();
  }, [setRepository, fetchBranding]);

  React.useMemo(() => {
    const journalsInAgreement = (agreementId: string) => {
      let journalss: Journals[] = [];
      journals?.forEach((journal) => {
        if (journal.agreementId === agreementId) {
          journalss.push(journal);
        }
      });
      return journalss;
    };
    agreements?.forEach((agreement) => {
      agreement.journals = journalsInAgreement(agreement._id!);
    });
  }, [agreements, journals]);

  const activeAgreements = () => {
    if (!agreements) {
      return [];
    }
    return agreements.filter((x) => x.status === AgreementIsActive.active);
  };

  const activeJournalsCount = () => {
    const agreementsActive = activeAgreements();
    if (agreementsActive.length === 0) {
      return 0;
    }
    return agreementsActive
      .map((x) => x.journals_count)
      .reduce((a, b) => {
        return a! + b!;
      });
  };

  const sortedAgreements = React.useMemo(() => {
    if (!agreements) return [];

    return agreements.sort((a, b) => {
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      if (valueA! > valueB!) return order === 'asc' ? 1 : -1;
      if (valueA! < valueB!) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }, [agreements, order, orderBy]);

  if (isLoading) return <Loader />;

  const getArrowIcon = (key: keyof Agreements) => {
    if (orderBy === key) {
      return order === 'asc' ? (
        <ExpandMore fontSize="small" style={arrowStyling} />
      ) : (
        <ExpandMore fontSize="small" style={arrowStylingDefault} />
      );
    }
    return <UnfoldMore fontSize="small" style={arrowStylingDefault} />;
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        p={12}
        component={Paper}
        mb={12}
      >
        <Grid item>
          <Typography color="secondary" variant="h1" sx={{ fontSize: 20 }}>
            {t('agreements.title')}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Create or edit your agreements for your public search
          </Typography>
          <Typography
            sx={{ marginTop: 28 }}
            variant="subtitle2"
            color="secondary"
          >
            You have {activeAgreements().length} active agreements with a total
            of {activeJournalsCount()} journals in your public search.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              sx={{ width: 200 }}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                newAgreement(event, navigate)
              }
              endIcon={<PlaylistAdd />}
              disabled={impersonationLock}
            >
              {t('agreements.addAgreement')}
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            {repository && (
              <a href={repository} target="_blank" rel="noreferrer">
                <StyledRepositoryLinkButton
                  variant="contained"
                  color="secondary"
                  sx={{ width: 200, mt: 24 }}
                  endIcon={<OpenInNew />}
                >
                  Repository Link
                </StyledRepositoryLinkButton>
              </a>
            )}
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <StyledTable
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: '2px solid #E0E0E0' }}>
              <TableCell align="left" width="20%">
                <StyledTableButton
                  onClick={(event) => handleRequestSort(event, 'publisher')}
                  sx={{ justifyContent: 'left' }}
                >
                  <StyledTableDiv>
                    {t('agreements.table.publisher')}
                  </StyledTableDiv>
                  {getArrowIcon('publisher')}
                </StyledTableButton>
              </TableCell>
              <TableCell align="left" width="20%">
                <StyledTableButton
                  onClick={(event) => handleRequestSort(event, 'name')}
                  sx={{ justifyContent: 'left' }}
                >
                  <StyledTableDiv>{t('agreements.table.title')}</StyledTableDiv>
                  {getArrowIcon('name')}
                </StyledTableButton>
              </TableCell>
              <TableCell align="center" width="20%">
                <StyledTableButton
                  onClick={(event) => handleRequestSort(event, 'start_date')}
                >
                  <StyledTableDiv>
                    {t('agreements.table.startDate')}
                  </StyledTableDiv>
                  {getArrowIcon('start_date')}
                </StyledTableButton>
              </TableCell>
              <TableCell align="center" width="20%">
                <StyledTableButton
                  onClick={(event) => handleRequestSort(event, 'end_date')}
                >
                  <StyledTableDiv>
                    {t('agreements.table.endDate')}
                  </StyledTableDiv>
                  {getArrowIcon('end_date')}
                </StyledTableButton>
              </TableCell>
              <TableCell align="center" width="10%">
                <StyledTableButton
                  onClick={(event) => handleRequestSort(event, 'status')}
                >
                  <StyledTableDiv>
                    {t('agreements.table.status')}
                  </StyledTableDiv>
                  {getArrowIcon('status')}
                </StyledTableButton>
              </TableCell>
              <TableCell align="center" width="10%">
                <StyledTableButton
                  onClick={(event) =>
                    handleRequestSort(event, 'journals_count')
                  }
                >
                  <StyledTableDiv>
                    {t('agreements.table.numberOfJournals')}
                  </StyledTableDiv>
                  {getArrowIcon('journals_count')}
                </StyledTableButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAgreements?.map((agreement) => (
              <StyledTableRow
                key={agreement._id}
                onClick={(event) => handleOpen(agreement._id!, event, navigate)}
              >
                <TableCell align="left" width="20%">
                  {agreement.publisher}
                </TableCell>
                <TableCell align="left" width="20%">
                  {agreement.name}
                </TableCell>
                <TableCell align="center" width="20%">
                  {dayjs(agreement.start_date?.slice(0, -4)).format(
                    'YYYY-MM-DD'
                  )}
                </TableCell>
                <TableCell align="center" width="20%">
                  {dayjs(agreement.end_date?.slice(0, -4)).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell align="center" width="10%">
                  <StyledTableBox status={agreement.status}>
                    {agreement.status}
                  </StyledTableBox>
                </TableCell>
                <TableCell align="center" width="10%">
                  {agreement.journals_count}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};
