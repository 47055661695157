import { Alert, AlertTitle, Typography } from '@mui/material';
import { t } from 'i18next';
import { ErrorProps } from '../../types/errorhandling.types';
import { useMemo } from 'react';

const ErrorBanner = ({
  errors,
  errorMessage,
  severity,
  layer,
  styles,
}: ErrorProps) => {
  const reasons = useMemo(() => (errors ? Object.keys(errors) : []), [errors]);
  const marginDistance = useMemo(() => {
    if (layer === 1) {
      return 64 * layer;
    }
    return 54 * layer;
  }, [layer]);

  const alertHeight = useMemo(() => {
    if (!errors || reasons.length <= 1) return 80;
    return 54 * reasons.length;
  }, [errors, reasons.length]);

  return (
    <Alert
      style={styles}
      severity={severity}
      sx={{
        mb: 12,
        position: 'sticky',
        top: marginDistance,
        zIndex: 2,
        height: alertHeight,
      }}
      onClick={() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }}
    >
      <AlertTitle>
        <Typography
          variant="h3"
          sx={{ fontSize: 16, textTransform: 'capitalize' }}
        >
          {severity}
        </Typography>
      </AlertTitle>
      {errorMessage && (
        <Typography variant="subtitle1">{errorMessage}</Typography>
      )}
      {reasons &&
        reasons.map((st) => (
          <Typography key={st} variant="subtitle1">
            {t(`agreements.errors.${st}`)}
          </Typography>
        ))}
    </Alert>
  );
};

export default ErrorBanner;
