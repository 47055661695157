import styled from '@emotion/styled';
import {
  Box,
  Button,
  CardContent,
  Fab,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

import { AssignmentTurnedIn, Clear, ContentPaste } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { parse } from 'marked';
import ErrorBanner from '../../components/errorhandling/ErrorBanner';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBranding } from '../../hooks/api/useBranding';
import { ErrorObject, errorSeverity } from '../../types/errorhandling.types';
import { resizeImage } from '../../utils/imageUtils';
import GeneralTab from './components/GeneralTab';
import { IconsTab } from './components/IconsTab';
import { SuccessAlert } from './components/SuccessAlert';
import { TabSelect } from './components/TabSelect';
import TextTab from './components/TextTab';
import { addPropertyIfNotEmpty } from './utils';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const Container = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const PresetInput = styled.input`
  && {
    font-size: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    border: none;
    width: 412px;
    &::placeholder {
      color: #404040;
      font-weight: 400;
    }
  }
`;

const StyledButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

export interface Icons {
  [key: string]: Icon;
}

export interface Icon {
  url: string;
  description: string | undefined;
  icon: string;
}

const Branding = () => {
  const [errorReason, setErrorReason] = useState<ErrorObject>();
  const [showToast, setShowToast] = useState(false);
  const [logoImage, setLogoImage] = useState<string | undefined>();
  const [logoImageBase64, setLogoImageBase64] = useState('');
  const [logoTag, setLogoTag] = useState('');
  const [logoLink, setLogoLink] = useState('');
  const [mainColor, setMainColor] = useState('');
  const [headerColor, setHeaderColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [infoBoxColor, setInfoBoxColor] = useState('');
  const [tableHeaderColor, setTableHeaderColor] = useState('');
  const [email, setEmail] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [infoBoxText, setInfoBoxText] = useState('');
  const [startingInstructionText, setStartingInstructionText] = useState('');
  const [nothingFoundText, setNothingFoundText] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [cancel, setCancel] = useState(false);
  const [name, setName] = useState('');
  const [tab, setTab] = useState('general');
  const [repositoryUrl, setRepositoryUrl] = useState('');
  const [paymentTitle, setPaymentTitle] = useState('');
  const [publishingModelUrl, setPublishingModelUrl] = useState('');
  const [licensesUrl, setLicensesUrl] = useState('');
  const [customLicenseUrl, setCustomLicenseUrl] = useState('');
  const [openInNewWindow, setOpenInNewWindow] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [copied, setCopied] = useState<boolean>(false);
  const [payments, setPayments] = useState<Icons>();
  const [licenses, setLicenses] = useState<Icons>();

  //Icons
  const [allPayments, setAllPayments] = useState<Icons>({});
  const [allLicenses, setAllLicenses] = useState<Icons>({});

  const { fetchBranding, updateBranding, fetchCustomInfo } = useBranding();
  const { loggedInAccount, account, impersonationLock } = useAuthContext();
  const publicUrl =
    process.env.REACT_APP_PUBLIC_SEARCH_URL || 'https://search.scifree.se';

  const mapIcons = useCallback((iconsToMap: Icons, referenceIcons: Icons) => {
    if (!iconsToMap) return undefined;
    const mappedIcons: Icons = {
      ...iconsToMap,
    };
    Object.keys(mappedIcons).forEach((key) => {
      if (mappedIcons[key].description === '') {
        mappedIcons[key].description = referenceIcons[key]?.description;
      }
    });
    return mappedIcons;
  }, []);

  useEffect(() => {
    const fetchAll = async () => {
      const customInfo = await fetchCustomInfo();
      setAllPayments(customInfo?.payments || {});
      setAllLicenses(customInfo?.licenses || {});
      const branding = await fetchBranding();
      setLogoImage(branding.logo_image ?? '');
      setLogoTag(branding.logo_tag ?? '');
      setName(branding.name ?? '');
      setMainColor(branding.main_color ?? '');
      setEmail(branding.email ?? '');
      setLogoLink(branding.logo_link ?? '');
      setSearchPlaceholder(branding.search_helper ?? '');
      setNothingFoundText(
        branding.no_results ? parse(branding.no_results) : ''
      );
      setSearchTitle(
        branding.search_title
          ? parse(branding.search_title)
          : '<h2><strong><em>Search for the Journals</em></strong></h2><p><strong>included in open access agreements</strong></p>'
      );
      setInfoBoxText(branding.info_box ? parse(branding.info_box) : '');
      setStartingInstructionText(
        branding.instructions ? parse(branding.instructions) : ''
      );
      setSecondaryColor(branding.secondary_color ?? '');
      setHeaderColor(branding.header_color ?? '');
      setInfoBoxColor(branding.info_box_color ?? '');
      setTableHeaderColor(branding.table_header_color ?? '');
      setAdditionalInfo(branding.additional_info ?? '');
      setRepositoryUrl(branding.repo_url ?? '');
      setPaymentTitle(branding.payment_title ?? '');
      setPublishingModelUrl(branding.publishing_model_url ?? '');
      setLicensesUrl(branding.licenses_url ?? '');
      setCustomLicenseUrl(branding.custom_license_url ?? '');
      setOpenInNewWindow(branding.open_in_new_window ?? true);
      setPayments(mapIcons(branding.payments, customInfo?.payments));
      setLicenses(mapIcons(branding.licenses, customInfo?.licenses));
      setCancel(false);
    };
    fetchAll();
  }, [cancel, fetchBranding, fetchCustomInfo, mapIcons]);

  useEffect(() => {
    if (logoImage) {
      const imageData =
        logoImage?.replace(/^data:image\/(svg\+xml|png|jpeg);base64,/, '') ??
        '';
      const decodedData = atob(imageData);
      const base64String = btoa(decodedData);

      setLogoImageBase64(base64String);
    } else {
      setLogoImageBase64('');
    }
  }, [logoImage]);

  const handleLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const resizedImage = await resizeImage(selectedFile);
      setLogoImage(resizedImage);
    }
  };

  const data: Record<string, any> = {};

  const modifyAndSetURL = (data: any, key: string) => {
    let modifiedURL = data[key]?.trim() || '';
    if (!modifiedURL.startsWith('https://')) {
      if (!modifiedURL.startsWith('http://')) {
        modifiedURL = `https://${modifiedURL}`;
      }
      data[key] = modifiedURL;
    }
  };

  addPropertyIfNotEmpty(data, 'logo_link', logoLink);
  modifyAndSetURL(data, 'logo_link');
  addPropertyIfNotEmpty(data, 'logo_image', logoImageBase64);
  data['logo_tag'] = logoTag || '';
  addPropertyIfNotEmpty(data, 'main_color', mainColor);
  addPropertyIfNotEmpty(data, 'header_color', headerColor);
  addPropertyIfNotEmpty(data, 'secondary_color', secondaryColor);
  addPropertyIfNotEmpty(data, 'info_box_color', infoBoxColor);
  addPropertyIfNotEmpty(data, 'table_header_color', tableHeaderColor);
  addPropertyIfNotEmpty(data, 'email', email);
  addPropertyIfNotEmpty(data, 'search_title', searchTitle);
  addPropertyIfNotEmpty(data, 'info_box', infoBoxText);
  addPropertyIfNotEmpty(data, 'instructions', startingInstructionText);
  addPropertyIfNotEmpty(data, 'no_results', nothingFoundText);
  addPropertyIfNotEmpty(data, 'search_helper', searchPlaceholder);
  addPropertyIfNotEmpty(data, 'additional_info', additionalInfo);
  addPropertyIfNotEmpty(data, 'name', name);
  addPropertyIfNotEmpty(data, 'repo_url', repositoryUrl);
  addPropertyIfNotEmpty(data, 'payment_title', paymentTitle);
  addPropertyIfNotEmpty(data, 'publishing_model_url', publishingModelUrl);
  addPropertyIfNotEmpty(data, 'licenses_url', licensesUrl);
  addPropertyIfNotEmpty(data, 'custom_license_url', customLicenseUrl);
  addPropertyIfNotEmpty(data, 'open_in_new_window', openInNewWindow);
  modifyAndSetURL(data, 'repo_url');
  modifyAndSetURL(data, 'publishing_model_url');
  modifyAndSetURL(data, 'licenses_url');
  modifyAndSetURL(data, 'custom_license_url');

  const handleUploadClick = async () => {
    if (!!payments) data.payments = payments;
    if (!!licenses) data.licenses = licenses;
    try {
      const res = await updateBranding({ data });
      const customInfo = await fetchCustomInfo();
      setAllPayments(customInfo?.payments);
      setAllLicenses(customInfo?.licenses);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 1600);
      const responseData = res;
      setRepositoryUrl(responseData.repo_url ?? '');
      setPaymentTitle(responseData.payment_title ?? '');
      setPublishingModelUrl(responseData.publishing_model_url ?? '');
      setLicensesUrl(responseData.licenses_url ?? '');
      setCustomLicenseUrl(responseData.custom_license_url ?? '');
      setOpenInNewWindow(responseData.open_in_new_window ?? true);
      setLogoImage(responseData.logo_image ?? '');
      setLogoTag(responseData.logo_tag ?? '');
      setName(responseData.name ?? '');
      setMainColor(responseData.main_color ?? '');
      setEmail(responseData.email ?? '');
      setLogoLink(responseData.logo_link ?? '');
      setSearchPlaceholder(responseData.search_helper ?? '');
      setNothingFoundText(
        responseData.no_results ? parse(responseData.no_results) : ''
      );
      setSearchTitle(
        responseData.search_title ? parse(responseData.search_title) : ''
      );
      setInfoBoxText(responseData.info_box ? parse(responseData.info_box) : '');
      setStartingInstructionText(
        responseData.instructions ? parse(responseData.instructions) : ''
      );
      setSecondaryColor(responseData.secondary_color ?? '');
      setInfoBoxColor(responseData.info_box_color ?? '');
      setTableHeaderColor(responseData.table_header_color ?? '');
      setHeaderColor(responseData.header_color ?? '');
      setAdditionalInfo(responseData.additional_info ?? '');
      setPayments(mapIcons(responseData.payments, customInfo.payments));
    } catch (error: any) {
      const reasons: ErrorObject = error.response.data;
      setErrorReason(reasons);
      setTimeout(() => {
        setErrorReason(undefined);
      }, 5000);
    }
  };

  const handleTabSelect = (tab: string) => {
    setTab(tab);
  };

  const handleSubmit = () => {
    window.open(`${publicUrl}/${account}?term=${searchTerm}`, '_blank');
  };

  const SearchButton = styled(Button)`
    && {
      margin-left: 12px;
      height: 42px;
      background-color: ${mainColor};
      &:hover {
        background-color: ${mainColor}95;
      }
      &:focus {
        outline: 2px solid #177d8e;
      }
    }
  `;

  const codeSnippet = `
  <div id="scfwid" data-account="${account}"></div>
  <script src="${publicUrl}/assets/external/widget.js"></script>
  `;

  return (
    <Card>
      <Grid container spacing={2} p={16}>
        {showToast && <SuccessAlert />}
        {errorReason && (
          <ErrorBanner
            errors={errorReason}
            severity={errorSeverity.Error}
            layer={0}
          />
        )}
        <Grid container justifyContent="space-between">
          <Grid item xs={9}>
            <Typography variant="h1" sx={{ fontSize: 20 }}>
              Branding
            </Typography>
            <Typography variant="subtitle1" color="secondary">
              Make customisations for your public search page including texts,
              colours and icons
            </Typography>
          </Grid>
          {tab !== 'widget' && (
            <Grid item>
              <StyledButton
                variant="outlined"
                endIcon={<ClearIcon />}
                disabled={impersonationLock}
                color="primary"
                sx={{ mr: '12px' }}
                onClick={() => {
                  setCancel(true);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                variant="contained"
                disabled={impersonationLock}
                endIcon={<SaveIcon />}
                color="primary"
                onClick={handleUploadClick}
              >
                Save
              </StyledButton>
            </Grid>
          )}
        </Grid>
        <Divider />
        <Box sx={{ width: '100%' }}>
          <TabSelect onTabSelect={handleTabSelect} />
          <Card mb={9}>
            <CardContent>
              {tab === 'general' && (
                <GeneralTab
                  setName={setName}
                  name={name}
                  loggedInAccount={loggedInAccount}
                  logoLink={logoLink}
                  setLogoLink={setLogoLink}
                  handleLogoChange={handleLogoChange}
                  logoImage={logoImage}
                  logoTag={logoTag}
                  setLogoTag={setLogoTag}
                  mainColor={mainColor}
                  setMainColor={setMainColor}
                  headerColor={headerColor}
                  setHeaderColor={setHeaderColor}
                  secondaryColor={secondaryColor}
                  setSecondaryColor={setSecondaryColor}
                  infoBoxColor={infoBoxColor}
                  tableHeaderColor={tableHeaderColor}
                  setTableHeaderColor={setTableHeaderColor}
                  setInfoBoxColor={setInfoBoxColor}
                  setEmail={setEmail}
                  email={email}
                  setSearchPlaceholder={setSearchPlaceholder}
                  searchPlaceholder={searchPlaceholder}
                  setAdditionalInfo={setAdditionalInfo}
                  additionalInfo={additionalInfo}
                  setRepositoryUrl={setRepositoryUrl}
                  repositoryUrl={repositoryUrl}
                  setPaymentTitle={setPaymentTitle}
                  paymentTitle={paymentTitle}
                  setPublishingModelUrl={setPublishingModelUrl}
                  publishingModelUrl={publishingModelUrl}
                  setLicensesUrl={setLicensesUrl}
                  licensesUrl={licensesUrl}
                  setCustomLicenseUrl={setCustomLicenseUrl}
                  openInNewWindow={openInNewWindow}
                  setOpenInNewWindow={setOpenInNewWindow}
                  customLicenseUrl={customLicenseUrl}
                  readonly={impersonationLock}
                />
              )}
              {tab === 'text' && (
                <TextTab
                  searchTitle={searchTitle}
                  setSearchTitle={setSearchTitle}
                  infoBoxText={infoBoxText}
                  setInfoBoxText={setInfoBoxText}
                  startingInstructionText={startingInstructionText}
                  setStartingInstructionText={setStartingInstructionText}
                  nothingFoundText={nothingFoundText}
                  setNothingFoundText={setNothingFoundText}
                />
              )}
              {tab === 'icons' && (
                <IconsTab
                  payments={allPayments}
                  selectedPayments={payments}
                  licenses={allLicenses}
                  selectedLicenses={licenses}
                  onPaymentsChange={setPayments}
                  onLicensesChange={setLicenses}
                />
              )}
              {tab === 'widget' && (
                <Container>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        sx={{ fontSize: 20, mb: 12, pl: 12 }}
                      >
                        Search Widget for JST
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <label style={{ display: 'none' }} htmlFor="search">
                        Search
                      </label>
                      <PresetInput
                        id="search"
                        placeholder={searchPlaceholder}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <Fab
                        size="small"
                        sx={{
                          background: 'none',
                          border: '1px solid black',
                          position: 'absolute',
                          my: 12,
                          transform: 'translateX(-50px)',
                          zIndex: 1,
                          '&:focus': {
                            outline: '2px solid #177d8e',
                          },
                        }}
                        onClick={() => setSearchTerm('')}
                        aria-label="Clear search input field"
                      >
                        <Clear color="secondary" />
                      </Fab>
                      <SearchButton
                        variant="contained"
                        onClick={() => handleSubmit()}
                      >
                        Search
                      </SearchButton>
                    </Grid>
                    <Grid item xs={12}>
                      <pre
                        style={{
                          overflow: 'scroll',
                          width: 600,
                          border: '1px solid grey',
                          borderRadius: 15,
                          paddingLeft: 12,
                          paddingBottom: 12,
                          backgroundColor: '#0a313f',
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{ fontSize: 16, pt: 12, color: '#ffffff' }}
                        >
                          Code Snippet for Search Widget
                          <Button
                            endIcon={
                              copied ? <AssignmentTurnedIn /> : <ContentPaste />
                            }
                            onClick={() => {
                              navigator.clipboard.writeText(codeSnippet);
                              setCopied(true);
                            }}
                            sx={{
                              left: 65,
                              color: 'white',
                            }}
                          >
                            {copied
                              ? 'Copied to Clipboard'
                              : 'Copy to Clipboard'}
                          </Button>
                        </Typography>
                        <code
                          id="snippet"
                          style={{
                            color: '#ffffff',
                          }}
                        >
                          {codeSnippet}
                        </code>
                      </pre>
                    </Grid>
                  </Grid>
                </Container>
              )}
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Card>
  );
};

export default Branding;
