import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SidebarItemsType } from '../../types/sidebar';
import SidebarNavSection from './SidebarNavSection';

import '../../vendor/perfect-scrollbar.css';

const baseScrollbar = (props: any) => css`
  background-color: ${process.env.REACT_APP_BUILD === 'staging'
    ? '#083833'
    : '#0b313f'};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(10)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

type SidebarNavProps = {
  pages: SidebarItemsType[];
};

const SidebarNav: React.FC<SidebarNavProps> = ({ pages }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;
  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {pages &&
            pages.map((page) => (
              <SidebarNavSection
                component="div"
                key={page.icon + page.title}
                pages={page.children}
                title={page.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
