import React, { useEffect, useState } from 'react';
import { AgreementForm } from './AgreementForm.view';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import { useParams } from 'react-router-dom';
import { Agreements } from '../../../../types/Agreements.types';
import Loader from '../../../../components/Loader';

function AgreementFormContainer() {
  const { fetchAgreementById } = useAgreements();
  const { agreementId } = useParams<{ agreementId: string }>();
  const [agreement, setAgreement] = useState<Agreements>();

  useEffect(() => {
    const fetchAgreement = async () => {
      if (agreementId) {
        const agreement = await fetchAgreementById(agreementId);
        if (agreement) {
          setAgreement(agreement);
        }
      }
    };
    fetchAgreement();
  }, [fetchAgreementById, agreementId]);

  if (!agreement && agreementId) return <Loader />;

  return <AgreementForm agreement={agreement} />;
}

export default AgreementFormContainer;
