import { Agreements } from './Agreements.types';

export type Journals = {
  name: string;
  whoPays?: string;
  issn_e?: string;
  issn_p?: string;
  imprint?: string;
  payment?: string;
  subjects?: string;
  extra_info?: string;
  journalStatus?: string;
  publishing_model?: JournalPublishingModel | undefined;
  licenses?: string[];
  url?: string;
  _id?: string;
  agreementId: Agreements['_id'];
};

export enum JournalPublishingModel {
  oa = 'Open Access',
  hybrid = 'Hybrid',
  ca = 'Closed Access',
}
