import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';

export const useBranding = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const fetchBranding = useCallback(async () => {
    return (await makeApiRequest)('/branding', 'GET');
  }, [makeApiRequest]);

  const updateBranding = async ({ data }: any) => {
    return (await makeApiRequest)('/branding', 'PUT', data);
  };

  const fetchCustomInfo = useCallback(async () => {
    if (account) {
      return (await makeApiRequest)(`/custom/${account}.json`, 'GET');
    }
  }, [makeApiRequest, account]);

  return { fetchBranding, updateBranding, fetchCustomInfo };
};

export const useDeleteBranding = () => {
  const { makeApiRequest } = useApi();

  const deleteBranding = async (account: string) => {
    return makeApiRequest('/branding', 'DELETE');
  };

  return { deleteBranding };
};
