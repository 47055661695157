

export const navigateToAgreement = (agreementId: string,event:React.MouseEvent<HTMLElement>,navigate:any) => {
    if(event.ctrlKey || event.metaKey)
    {
        window.open(`/agreements/${agreementId}`,'_blank');
    }
    else
    {
        navigate(`/agreements/${agreementId}`);
    }
    
}

export const navigateToNewAgreement = (event: React.MouseEvent<HTMLElement>, navigate: any) => {
    navigate(`/agreements/add`)
}