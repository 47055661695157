import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';

import { CssBaseline } from '@mui/material';

import GlobalStyle from '../components/GlobalStyle';

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: #ffffff;
`;

interface AuthType {
  children?: React.ReactNode;
}

const Auth: React.FC<AuthType> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
