import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillWrapper = styled.div`
  .ql-container .ql-editor {
    height: 100px;
    font-style: normal;
  }

  @media (min-width: 768px) {
    .ql-container .ql-editor {
      height: 120px;
    }
  }
`;

type QuillProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
};

const Quill = ({ value, onChange, placeholder, disabled }: QuillProps) => {
  return (
    <QuillWrapper>
      <Grid
        sx={{
          width: { xs: '100%', md: '80%', lg: '70%' },
        }}
      >
        <ReactQuill
          theme="snow"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={disabled}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
        />
      </Grid>
    </QuillWrapper>
  );
};

export default Quill;
