import React, { useEffect } from 'react';
import { AgreementsTableView } from './AgreementsTable.view';
import { Agreements } from '../../../../types/Agreements.types';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import { useAuthContext } from '../../../../contexts/AuthContext';

function AgreementsTableContainer() {
  const { fetchAgreements } = useAgreements();
  const { account } = useAuthContext();
  const [agreements, setAgreements] = React.useState<Agreements[]>();

  useEffect(() => {
    const getAgreements = async () => {
      const res: Agreements[] = await fetchAgreements();
      setAgreements(res);
    };
    getAgreements();
  }, [fetchAgreements, account]);
  return <AgreementsTableView agreements={agreements!} journals={[]} />;
}

export default AgreementsTableContainer;
