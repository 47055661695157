import { useState, useEffect } from 'react';

export function useToast(duration = 1600) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (show) {
      timer = setTimeout(() => {
        setShow(false);
      }, duration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [show, duration]);

  const trigger = () => setShow(true);

  return { show, trigger };
}
