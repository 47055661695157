import styled from '@emotion/styled';
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorBanner from '../../../../components/errorhandling/ErrorBanner';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import {
  JournalPublishingModel,
  Journals,
} from '../../../../types/Journals.types';
import {
  ErrorObject,
  errorSeverity,
} from '../../../../types/errorhandling.types';
import { DeleteAgreementModal } from '../agreement/DeleteAgreementModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { StyledTextArea } from '../../presetStyles/formPresets';

const StyledLabel = styled.label`
  color: #666666;
  font-size: 11px;
`;

export const JournalModal = ({
  journal,
  open,
  handleClose,
  editMode,
}: {
  journal: Journals | undefined;
  open: boolean;
  handleClose: () => void;
  editMode: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { impersonationLock } = useAuthContext();

  const { addJournal, editJournal } = useAgreements();

  const [journals, setJournals] = React.useState<Journals[]>();
  const [paymentOptions, setPaymentOptions] = React.useState<string[]>([]);
  const [licenseOption, setLicenseOption] = React.useState<string[]>([]);

  interface FormDataType {
    name: string;
    imprint: string;
    publishing_model: string;
    issn_e: string;
    issn_p: string;
    payment: string;
    subjects: string;
    extra_info: string;
    licenses: string[];
    url: string;
  }

  const formData: FormDataType = {
    name: '',
    imprint: '',
    publishing_model: '',
    issn_e: '',
    issn_p: '',
    payment: '',
    subjects: '',
    extra_info: '',
    licenses: [],
    url: '',
  };

  const { agreementId } = useParams<{ agreementId: string }>();

  const [requestBody, setRequestBody] = useState<FormDataType>(formData);
  const {
    name,
    imprint,
    publishing_model: publishingModel,
    issn_e: issne,
    issn_p: issnp,
    payment,
    subjects: subject,
    extra_info: extraInfo,
    licenses,
    url,
  } = requestBody;

  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [errors, setErrors] = useState<ErrorObject | undefined>();

  const { fetchPayments, fetchLicenses } = useAgreements();

  React.useEffect(() => {
    const fetchData = async () => {
      const selectableLicenses = await fetchLicenses();
      setLicenseOption(selectableLicenses);
    };
    setJournals(journals);
    fetchPayments().then((res) => setPaymentOptions(res));

    fetchData().catch((err) => console.log(err));
  }, [journals, fetchPayments, fetchLicenses]);

  React.useMemo(() => {
    setRequestBody({
      name: journal?.name ?? '',
      imprint: journal?.imprint ?? '',
      publishing_model: journal?.publishing_model ?? '',
      issn_e: journal?.issn_e ?? '',
      issn_p: journal?.issn_p ?? '',
      payment: journal?.payment ?? '',
      subjects: journal?.subjects ?? '',
      extra_info: journal?.extra_info ?? '',
      licenses: journal?.licenses ?? [],
      url: journal?.url ?? '',
    });
  }, [journal]);

  const onCloseHandler = () => {
    setError(undefined);
    setErrors(undefined);
    handleClose();
  };

  const requiredFieldsMissing = useMemo(() => {
    return (
      !requestBody.name ||
      !requestBody.publishing_model ||
      requestBody.licenses.length === 0 ||
      !requestBody.payment ||
      !requestBody.imprint ||
      !requestBody.url
    );
  }, [requestBody]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setError(undefined);
    setErrors(undefined);
    setRequestBody({ ...requestBody, [name]: value });
    if (!changesMade) {
      setChangesMade(true);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError(undefined);
    setErrors(undefined);

    try {
      if (editMode) {
        await editJournal(requestBody, agreementId!, journal?._id!);
      } else {
        await addJournal(requestBody, agreementId!);
      }
      setChangesMade(false);
      onCloseHandler();
      navigate(0);
    } catch (error: any) {
      const errorExists = !!error.response.data;
      if (errorExists) {
        setErrors(error.response.data);
      } else {
        setError(
          'Something went wrong. Please look through the fields and try again.'
        );
      }
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseHandler}
        sx={{ overflow: 'scroll', margin: 'auto' }}
      >
        <Card sx={{ width: '50%', margin: 'auto', my: 36 }}>
          {error && (
            <ErrorBanner
              errorMessage={error}
              severity={errorSeverity.Error}
              layer={0}
            />
          )}
          {errors && (
            <ErrorBanner
              errors={errors}
              severity={errorSeverity.Error}
              layer={error ? 1 : 0}
            />
          )}
          <Grid container>
            <Grid item xs={9}>
              <Typography
                sx={{ marginLeft: '12px', marginTop: '12px' }}
                variant="h4"
                gutterBottom
              >
                {editMode ? 'Edit journal' : `Create new journal`}
              </Typography>
              <small style={{ marginLeft: '12px' }}>
                {t('agreements.asteriskRequired')}
              </small>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                pt: 18,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            >
              {editMode ? (
                <DeleteAgreementModal
                  id={agreementId!}
                  journalId={journal?._id!}
                  journalMode
                />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid container sx={{ padding: '12px' }}>
              <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                <StyledLabel htmlFor="name">
                  {`${t('agreements.journal')} *`}
                </StyledLabel>
                <TextField
                  sx={{ backgroundColor: name ? '' : '#fff4e5' }}
                  fullWidth
                  required
                  id="name"
                  name="name"
                  defaultValue={name}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                <StyledLabel htmlFor="imprint">
                  {t('agreements.imprint')} *
                </StyledLabel>
                <TextField
                  sx={{ backgroundColor: imprint ? '' : '#fff4e5' }}
                  fullWidth
                  required
                  id="imprint"
                  name="imprint"
                  value={imprint}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                />
              </Grid>
              <Grid container spacing={12}>
                <Grid item xs={6}>
                  <StyledLabel htmlFor="payment">Payment Option *</StyledLabel>
                  <Select
                    sx={{ backgroundColor: payment ? '' : '#fff4e5' }}
                    fullWidth
                    required
                    id="payment"
                    name="payment"
                    onChange={(e) => handleChange(e)}
                    value={payment}
                    disabled={impersonationLock}
                  >
                    {paymentOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel htmlFor="licenses">
                    License Options *
                  </StyledLabel>
                  <Select
                    sx={{
                      backgroundColor: licenses.length > 0 ? '' : '#fff4e5',
                    }}
                    fullWidth
                    required
                    id="licenses"
                    name="licenses"
                    value={licenses}
                    multiple
                    onChange={(e) => handleChange(e)}
                    disabled={impersonationLock}
                  >
                    {licenseOption.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                <StyledLabel htmlFor="publishingModel">
                  Publishing Model *
                </StyledLabel>
                <Select
                  sx={{ backgroundColor: publishingModel ? '' : '#fff4e5' }}
                  fullWidth
                  required
                  id="publishing_model"
                  name="publishing_model"
                  value={publishingModel}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                >
                  <MenuItem value={JournalPublishingModel.oa}>
                    Open Access
                  </MenuItem>
                  <MenuItem value={JournalPublishingModel.hybrid}>
                    Hybrid
                  </MenuItem>
                  <MenuItem value={JournalPublishingModel.ca}>
                    Closed Access
                  </MenuItem>
                </Select>
              </Grid>
              <Grid container spacing={12}>
                <Grid item xs={6} sx={{ marginBottom: '12px' }}>
                  <StyledLabel htmlFor="issn_e">ISSN-E</StyledLabel>
                  <TextField
                    fullWidth
                    id="issn_e"
                    name="issn_e"
                    value={issne}
                    onChange={(e) => handleChange(e)}
                    disabled={impersonationLock}
                  />
                </Grid>
                <Grid item xs={6} sx={{ marginBottom: '12px' }}>
                  <StyledLabel htmlFor="issn_p">ISSN-P</StyledLabel>
                  <TextField
                    fullWidth
                    id="issn_p"
                    name="issn_p"
                    value={issnp}
                    onChange={(e) => handleChange(e)}
                    disabled={impersonationLock}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                <StyledLabel htmlFor="subject">Subject Areas</StyledLabel>
                <TextField
                  fullWidth
                  id="subjects"
                  name="subjects"
                  value={subject}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                <StyledLabel htmlFor="extra_info">
                  Extra information
                </StyledLabel>
                <StyledTextArea
                  id="extra_info"
                  name="extra_info"
                  value={extraInfo}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                  maxLength={400}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledLabel htmlFor="url">
                  {`${t('agreements.journalURL')}`} *
                </StyledLabel>
                <TextField
                  required
                  inputProps={{ pattern: 'http[s]?://.*' }}
                  type="url"
                  placeholder="https://www.example.com"
                  fullWidth
                  id="url"
                  name="url"
                  value={url}
                  onChange={(e) => handleChange(e)}
                  disabled={impersonationLock}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={{ marginRight: '12px', marginBottom: '12px' }}
                variant="outlined"
                onClick={onCloseHandler}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginRight: '12px', marginBottom: '12px' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  (!changesMade && editMode) ||
                  requiredFieldsMissing ||
                  impersonationLock
                }
              >
                {editMode ? t('agreements.save') : 'Create'}
              </Button>
            </Box>
          </form>
        </Card>
      </Modal>
    </div>
  );
};
