export type ErrorProps = {
  errors?: ErrorObject;
  errorMessage?: string;
  severity: errorSeverity;
  layer: number;
  styles?: React.CSSProperties;
};

export type ErrorObject = {
  [key: string]: string[];
};

export enum errorSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}
