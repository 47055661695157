import React, { ReactNode } from 'react';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { auth0Config } from '../config';

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const domain = auth0Config.domain || '';
  const clientId = auth0Config.clientId || '';
  const redirect_uri = auth0Config.redirectUri || 'https://app.jstscifree.com';

  const onRedirectCallback = (AppState: AppState | undefined) => {
    navigate(AppState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirect_uri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirect_uri,
        audience: auth0Config.audience,
        scope:
          'read:current_user update:current_user_metadata read:users openid profile email offline_access app_metadata',
        cacheLocation: 'localstorage',
      }}
      onRedirectCallback={onRedirectCallback}
      useCookiesForTransactions={true}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
