import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';

export const useSearch = () => {
  const { makeApiRequest } = useApi();
  const { account } = useAuthContext();

  const searchJournals = useCallback(
    async (
      searchQuery: string,
      licenses: string[],
      publishingModels: string[],
      payments: string[],
      pubhishers: string[]
    ) => {
      let params = `/search?term=${searchQuery}&account=${account}`;

      if (searchQuery === '') {
        return [];
      }

      if (licenses.length > 0) {
        params += `&licenses[]=${encodeURI(licenses.join('&licenses[]='))}`;
      }
      if (publishingModels.length > 0) {
        params += `&publishing_model[]=${encodeURI(
          publishingModels.join('&publishing_model[]=')
        )}`;
      }
      if (payments.length > 0) {
        params += `&payment[]=${encodeURI(payments.join('&payment[]='))}`;
      }
      if (pubhishers.length > 0) {
        params += `&imprint[]=${encodeURI(pubhishers.join('&imprint[]='))}`;
      }

      return (await makeApiRequest)(params, 'GET');
    },
    [makeApiRequest, account]
  );

  const getBaseFilters = useCallback(
    async (searchQuery: string) => {
      let response = await searchJournals(searchQuery, [], [], [], []);
      let licenses: Set<string> = new Set(response?.filters?.licenses);
      let publishingModels: Set<string> = new Set(
        response?.filters?.publishing_models
      );
      let payments: Set<string> = new Set(response?.filters?.payments);
      let imprints: Set<string> = new Set(response?.filters?.imprints);

      let sortSet = (set: Set<string>) => {
        return Array.from(set).sort((a, b) => {
          return a.localeCompare(b);
        });
      };

      return {
        licenses: sortSet(licenses),
        publishingModels: sortSet(publishingModels),
        payments: sortSet(payments),
        imprints: sortSet(imprints),
      };
    },
    [searchJournals]
  );

  return {
    searchJournals,
    getBaseFilters,
  };
};
