import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { Box, Drawer as MuiDrawer, ListItemButton } from '@mui/material';

import { SidebarItemsType } from '../../types/sidebar';
import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';
import StagingLogo from '../../assets/pictures/StagingLogo.png';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${process.env.REACT_APP_BUILD === 'staging'
    ? '#083833'
    : '#0b313f'};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(9)};
  padding-right: ${(props) => props.theme.spacing(6)};
  padding: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: #10495e;
  }
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
  pages: SidebarItemsType[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  pages,
  showFooter = true,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink as any} to="/">
        {process.env.REACT_APP_BUILD === 'staging' ? (
          <img
            src={StagingLogo}
            style={{ width: '140px', marginTop: 10 }}
            alt="Logo"
          />
        ) : (
          <Box
            ml={1}
            fontFamily="Helvetica Neue"
            fontWeight={700}
            fontSize={40}
            letterSpacing={1}
          >
            SciFree
          </Box>
        )}
      </Brand>
      <SidebarNav pages={pages} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
