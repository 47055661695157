import { Box, Typography, Link as MuiLink } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from 'react-router-dom';

const AlertBox = () => (
  <Box
    sx={{
      width: 230,
      mt: 20,
      p: 10,
      alignItems: 'center',
      border: '1px solid #c4c4c4',
      boxShadow: 1,
      color: 'text.primary',
    }}
  >
    <Typography variant="body2" fontWeight="bold">
      <WarningAmberIcon
        sx={{
          fontSize: 20,
          color: '#f5d8a3',
          mr: 6,
          mb: -2,
        }}
      />
      Make sure the contrast between the background and text meets{' '}
      <MuiLink
        component={Link}
        to="https://webaim.org/resources/contrastchecker/"
        target="_blank"
        color="primary.main"
        underline="hover"
      >
        WCAG standards
      </MuiLink>
    </Typography>
  </Box>
);

export default AlertBox;
