import styled from '@emotion/styled';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AlertBox from './AlertBox';
import { StyledQuestionMark } from '../../agreements/presetStyles/formPresets';

const TopWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const LogoLabel = styled.label`
  margin-bottom: 12px;
`;

const StyledColorContainer = styled(Grid)`
  border: 1px solid #c4c4c4;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLabel = styled.label`
  margin-right: 20px;
  color: #575959;
`;

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
`;

type GeneralTabProps = {
  setName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  loggedInAccount: string;
  logoLink: string;
  setLogoLink: React.Dispatch<React.SetStateAction<string>>;
  handleLogoChange: (e: ChangeEvent<HTMLInputElement>) => void;
  logoImage: string | undefined;
  logoTag: string;
  setLogoTag: React.Dispatch<React.SetStateAction<string>>;
  mainColor: string;
  setMainColor: React.Dispatch<React.SetStateAction<string>>;
  headerColor: string;
  setHeaderColor: React.Dispatch<React.SetStateAction<string>>;
  secondaryColor: string;
  setSecondaryColor: React.Dispatch<React.SetStateAction<string>>;
  infoBoxColor: string;
  setInfoBoxColor: React.Dispatch<React.SetStateAction<string>>;
  tableHeaderColor: string;
  setTableHeaderColor: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setSearchPlaceholder: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder: string;
  setAdditionalInfo: React.Dispatch<React.SetStateAction<string>>;
  additionalInfo: string;
  repositoryUrl: string;
  setRepositoryUrl: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTitle: React.Dispatch<React.SetStateAction<string>>;
  paymentTitle: string;
  publishingModelUrl: string;
  setPublishingModelUrl: React.Dispatch<React.SetStateAction<string>>;
  licensesUrl: string;
  setLicensesUrl: React.Dispatch<React.SetStateAction<string>>;
  customLicenseUrl: string;
  setCustomLicenseUrl: React.Dispatch<React.SetStateAction<string>>;
  readonly: boolean;
  openInNewWindow: boolean;
  setOpenInNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const GeneralTab = ({
  setName,
  name,
  loggedInAccount,
  logoLink,
  setLogoLink,
  handleLogoChange,
  logoImage,
  logoTag,
  setLogoTag,
  mainColor,
  setMainColor,
  headerColor,
  setHeaderColor,
  secondaryColor,
  setSecondaryColor,
  infoBoxColor,
  setInfoBoxColor,
  tableHeaderColor,
  setTableHeaderColor,
  setEmail,
  email,
  setSearchPlaceholder,
  searchPlaceholder,
  setAdditionalInfo,
  additionalInfo,
  repositoryUrl,
  setRepositoryUrl,
  setPaymentTitle,
  paymentTitle,
  publishingModelUrl,
  setPublishingModelUrl,
  licensesUrl,
  setLicensesUrl,
  customLicenseUrl,
  setCustomLicenseUrl,
  readonly,
  openInNewWindow,
  setOpenInNewWindow,
}: GeneralTabProps) => {
  return (
    <TopWrapper>
      <Box>
        <Typography variant="subtitle2" mb={10}>
          General
        </Typography>
      </Box>
      <div>
        <div>
          <Divider />
          <StyledBox
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <Typography
              variant="subtitle2"
              mb={10}
              flex={0.1}
              textAlign="start"
            >
              Branding
            </Typography>
            <div style={{ flex: '0.4' }}>
              <TextField
                name="name"
                label="Account name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  borderRadius: 0,
                  marginY: '14px',
                  width: '100%',
                }}
                InputProps={{
                  style: {
                    borderRadius: 0,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#575959',
                  },
                }}
                disabled={loggedInAccount !== 'admin' || readonly}
              />
              <TextField
                name="logoUrl"
                label="Brand Logo URL"
                type="url"
                value={logoLink}
                onChange={(e) => setLogoLink(e.target.value)}
                sx={{
                  borderRadius: 0,
                  marginY: '8px',
                  width: '100%',
                }}
                InputProps={{
                  style: {
                    borderRadius: 0,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#575959',
                  },
                }}
                disabled={readonly}
              />
              <Box
                display="flex"
                flexDirection="column"
                border="1px solid #C4C4C4"
                padding="10px"
                mb={16}
                mt={8}
              >
                <LogoLabel htmlFor="logoImage">
                  Brand Logo <b>(.png / .svg)</b>
                </LogoLabel>
                <input
                  type="file"
                  accept=".png, .svg"
                  id="logoImage"
                  name="logoImage"
                  onChange={handleLogoChange}
                  disabled={readonly}
                />
                <small style={{ marginTop: 6 }}>
                  <b>For best experience we recommend a 150px height.</b>
                </small>
              </Box>
              {logoImage && (
                <img
                  src={logoImage}
                  alt="Logo Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100px',
                    backgroundColor: headerColor,
                    padding: '10px',
                  }}
                />
              )}
              <TextField
                name="logoTag"
                label="Brand Logo Tag"
                type="text"
                value={logoTag}
                onChange={(e) => setLogoTag(e.target.value)}
                sx={{
                  borderRadius: 0,
                  marginY: '8px',
                  marginTop: '16px',
                  width: '100%',
                }}
                InputProps={{
                  style: {
                    borderRadius: 0,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#575959',
                  },
                }}
                disabled={readonly}
              />
            </div>
            <div style={{ flex: '0.4' }}>
              <div style={{ width: '230px', marginTop: '15px' }}>
                <StyledColorContainer item>
                  <StyledLabel htmlFor="mainColor">
                    Main color
                    <Tooltip
                      sx={{ marginLeft: '6px' }}
                      title="Changes header, text color and search images & buttons"
                    >
                      <StyledQuestionMark />
                    </Tooltip>
                  </StyledLabel>
                  <input
                    style={{ minWidth: '50px' }}
                    type="color"
                    id="mainColor"
                    name="mainColor"
                    value={mainColor || '#ffffff'}
                    onChange={(e) => setMainColor(e.target.value)}
                    disabled={readonly}
                  />
                </StyledColorContainer>
                <StyledColorContainer item>
                  <StyledLabel htmlFor="headerColor">
                    Header color{' '}
                    <Tooltip
                      sx={{ marginLeft: '6px' }}
                      title="Changes the page header, should be different to the main color selected"
                    >
                      <StyledQuestionMark />
                    </Tooltip>
                  </StyledLabel>
                  <input
                    style={{ minWidth: '50px' }}
                    type="color"
                    id="headerColor"
                    name="headerColor"
                    value={headerColor || '#ffffff'}
                    onChange={(e) => setHeaderColor(e.target.value)}
                    disabled={readonly}
                  />
                </StyledColorContainer>
                <StyledColorContainer item>
                  <StyledLabel htmlFor="secondaryColor">
                    Page Background color
                    <Tooltip
                      sx={{ marginLeft: '6px' }}
                      title="Changes background of the search page"
                    >
                      <StyledQuestionMark />
                    </Tooltip>
                  </StyledLabel>
                  <input
                    style={{ minWidth: '50px' }}
                    type="color"
                    id="secondaryColor"
                    name="secondaryColor"
                    value={secondaryColor || '#ffffff'}
                    onChange={(e) => setSecondaryColor(e.target.value)}
                    disabled={readonly}
                  />
                </StyledColorContainer>
                <StyledColorContainer item>
                  <StyledLabel htmlFor="infoBoxColor">
                    Information Box background color
                    <Tooltip
                      sx={{ marginLeft: '6px' }}
                      title="Changes information box background on right-hand side"
                    >
                      <StyledQuestionMark />
                    </Tooltip>
                  </StyledLabel>
                  <input
                    style={{ minWidth: '50px' }}
                    type="color"
                    id="infoBoxColor"
                    name="infoBoxColor"
                    value={infoBoxColor || '#e8e8e8'}
                    onChange={(e) => setInfoBoxColor(e.target.value)}
                    disabled={readonly}
                  />
                </StyledColorContainer>
                <StyledColorContainer item>
                  <StyledLabel htmlFor="tableHeaderColor">
                    Table Header background color
                    <Tooltip
                      sx={{ marginLeft: '6px' }}
                      title="Changes results table header background"
                    >
                      <StyledQuestionMark />
                    </Tooltip>
                  </StyledLabel>
                  <input
                    style={{ minWidth: '50px' }}
                    type="color"
                    id="tableHeaderColor"
                    name="tableHeaderColor"
                    value={tableHeaderColor || '#e8e8e8'}
                    onChange={(e) => setTableHeaderColor(e.target.value)}
                    disabled={readonly}
                  />
                </StyledColorContainer>
              </div>
              <AlertBox />
            </div>
          </StyledBox>
        </div>
        <Divider />
        <StyledBox
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
            Email & URLs
          </Typography>
          <div style={{ flex: '0.4' }}>
            <TextField
              name="email"
              label="Contact Us Icon Email / URL (must begin with https://)"
              type="text"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
            <TextField
              name="publishingModelUrl"
              label="Publishing Model URL"
              type="url"
              fullWidth
              onChange={(e) => setPublishingModelUrl(e.target.value)}
              value={publishingModelUrl}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
            <TextField
              name="licensesUrl"
              label="Licenses URL"
              type="url"
              fullWidth
              onChange={(e) => setLicensesUrl(e.target.value)}
              value={licensesUrl}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
            <TextField
              name="customLicenseURL"
              label="Custom license URL"
              type="url"
              fullWidth
              onChange={(e) => setCustomLicenseUrl(e.target.value)}
              value={customLicenseUrl}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
          </div>
          <div style={{ flex: '0.4' }}>
            <TextField
              name="repositoryUrl"
              label="Internal Repository URL"
              type="url"
              fullWidth
              onChange={(e) => setRepositoryUrl(e.target.value)}
              value={repositoryUrl}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
            <Grid
              sx={{
                width: '100%',
              }}
            >
              <InputLabel
                sx={{
                  fontSize: '11px',
                  color: '#575959',
                  marginLeft: '10px',
                }}
              >
                Additional info (*URL must begin with 'https://')
              </InputLabel>
              <ReactQuill
                readOnly={readonly}
                theme="snow"
                value={additionalInfo}
                onChange={setAdditionalInfo}
                placeholder="Additional info"
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={openInNewWindow}
                  onChange={(e) => setOpenInNewWindow(e.target.checked)}
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Open links in a new window."
              sx={{ marginTop: 2, color: '#575959' }}
            />
          </div>
        </StyledBox>
        <Divider />
        <StyledBox
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Typography variant="subtitle2" mb={10} flex={0.1} textAlign="start">
            Placeholder & Title
          </Typography>
          <div style={{ flex: 0.4 }}>
            <TextField
              name="searchPlaceholder"
              label="Edit text within the search bar"
              type="text"
              fullWidth
              onChange={(e) => setSearchPlaceholder(e.target.value)}
              value={searchPlaceholder}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
            <TextField
              name="paymentTitle"
              label="Title for Payment Column"
              type="text"
              fullWidth
              onChange={(e) => setPaymentTitle(e.target.value)}
              value={paymentTitle}
              sx={{ marginY: '8px' }}
              InputProps={{
                style: {
                  borderRadius: 0,
                },
              }}
              InputLabelProps={{
                style: {
                  color: '#575959',
                },
              }}
              disabled={readonly}
            />
          </div>
          <div style={{ flex: 0.4 }}></div>
        </StyledBox>
      </div>
    </TopWrapper>
  );
};

export default GeneralTab;
