import React from 'react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import { Chip, ListItemProps, ListItemText, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type TitleType = {
  depth: number;
};

const Title = styled(ListItemText)<TitleType>`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 7px;
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

type SidebarNavListItemProps = ListItemProps & {
  className?: string;
  depth: number;
  href: string;
  icon: any;
  badge?: string;
  open?: boolean;
  title: string;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const { title, href, depth = 0, children, icon: Icon, badge } = props;
  const navigate = useNavigate();

  if (children) {
    return (
      <React.Fragment>
        <Button key={title} variant="text">
          <Title depth={depth}>
            {Icon}
            {title}
            {badge && <Badge label={badge} />}
          </Title>
        </Button>
        {children}
      </React.Fragment>
    );
  }

  if (href.startsWith('http')) {
    return (
      <React.Fragment>
        <Button
          className="active"
          sx={{
            paddingY: '8px',
            marginLeft: '8px',
            '&:hover': { backgroundColor: '#12536b' },
          }}
          key={title}
          href={href}
          target="_blank"
          variant="text"
          fullWidth
        >
          {Icon && <Icon sx={{ color: '#EEEEEEB3' }} />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
        </Button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button
        className="active"
        sx={{
          paddingY: '8px',
          marginLeft: '8px',
          textAlign: 'left',
          '&:hover': { backgroundColor: '#12536b' },
        }}
        key={title}
        onClick={() => navigate(href)}
        variant="text"
        fullWidth
      >
        {Icon && <Icon sx={{ color: '#EEEEEEB3' }} />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Button>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
