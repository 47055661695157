import { useState, useEffect } from 'react';

type FeatureFlags = 'enableFilePreviews';
const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<{
    [key in FeatureFlags]: boolean;
  }>({
    enableFilePreviews: false,
  });

  useEffect(() => {
    const isStaging = process.env.REACT_APP_BUILD === 'staging';
    setFeatureFlags({ enableFilePreviews: isStaging });
  }, []);

  return featureFlags;
};

export default useFeatureFlags;
